import { createRef } from "react"
// import { Vector3 } from "three"

const state = {
  sections: 9,
  pages: 6.5, //8
  zoom: 75,
  paragraphs: [
    {
      offset: 1,
      factor: 1.75,
      header: "The Distance",
      image: "/assets/img/L1N1b.jpg",
      aspect: 1.51,
			text: "Never settle for what's done, always keep going. We find the operation of this command in the lives of all people who have achieved something."
    },
    {
      offset: 2,
      factor: 2.0,
      header: "The Shadow",
      image: "/assets/img/L8N1.jpg",
			aspect: 0.665,
      text: "I am surprised at the surprise of those who are surprised."
    },
    {
      offset: 3,
      factor: 2.25,
      header: "The X",
      image: "/assets/img/L4N1.jpg",
      aspect: 1.5037,
      text: "There is no such thing as a good war..."
    },
    {
      offset: 4,
      factor: 2.0,
      header: "The Monolith",
			image: "/assets/img/L5N1.jpg",
			aspect: 1.5,
      text: "When people are adapted to the system, and not the system to the people, the coercion that lies is necessary to bring theory into practice."
    },
    {
      offset: 5,
      factor: 1.75,
      header: "The Paradox",
      image: "/assets/img/L6N1.jpg",
      aspect: 0.75,
      text: "All things dissolve in time. There is no stronger solvent."
    },
    { offset: 7, 
			factor: 1.05,
			header: "The Sphere",
			image: "/assets/img/L7N1.jpg",
			aspect: 1.3,
			text: "Education and enlightenment."
		}
  ],
  stripes: [
    { offset: 0, color: "#000", height: 5 },
    { offset: 6.3, color: "#000", height: 30 }
  ],
  // diamonds: [
  //   { x: 0, offset: 0.15, pos: new Vector3(), scale: 0.6, factor: 1.8 },
  //   { x: 2, offset: 1.1, pos: new Vector3(), scale: 0.8, factor: 2.1 },
  //   { x: -5, offset: 2, pos: new Vector3(), scale: 0.8, factor: 2.5 },
  //   { x: 0, offset: 3.2, pos: new Vector3(), scale: 0.8, factor: 1.75 },
  //   { x: 0, offset: 4, pos: new Vector3(), scale: 0.8, factor: 2.5 },
  //   { x: 2, offset: 5.5, pos: new Vector3(), scale: 1.25, factor: 0.85 },
  //   { x: -5, offset: 7, pos: new Vector3(), scale: 0.8, factor: 2 },
  //   { x: 0, offset: 8, pos: new Vector3(), scale: 1.5, factor: 6 }
  // ],
  top: createRef()
}

export default state
